import { defineStore } from 'pinia';
import { hasProperty } from '@/helpers';
import { i18n } from '@/main';

export const useBreadcrumbsStore = defineStore('breadcrumbs', {
    state: () => ({
        breadcrumbs: {},
        entityDictionary: null
    }),

    getters: {},
    actions: {
        generateBreadcrumbForCard(cardName, instanceId, instanceObg, cardTitle, cardIcon) {
            let breadcrumbs = [];
            if (cardName) {
                breadcrumbs = this.entitySystemBreadcrumb(cardName, cardTitle, cardIcon);
            }
            const instanceBreadcrumb = {
                parrent: '',
                path: '',
                name: instanceObg.title,
                disabled: true
            };
            breadcrumbs.push(instanceBreadcrumb);
            this.breadcrumbs[instanceId] = breadcrumbs;
            return breadcrumbs;
        },
        entitySystemBreadcrumb(cardName, cardTitle, cardIcon) {
            if (!this.entityDictionary) {
                this.initDictionary();
            }
            let breadcrumbs = [];
            const addBreadcrumb = (target, cardName, cardTitle, cardIcon) => {
                if (!target) return;

                let cardData = target[cardName];

                if (cardTitle) {
                    cardData.name = cardTitle;
                }

                if (cardIcon) {
                    cardData.icon = cardIcon;
                }

                if (cardData) {
                    breadcrumbs.push(cardData);
                    if (cardData?.parrent) {
                        addBreadcrumb(target, cardData?.parrent);
                    }
                }
            };
            addBreadcrumb(this.entityDictionary, cardName, cardTitle, cardIcon);
            return breadcrumbs.reverse();
        },
        setBreadcrumb(instanceId, data) {
            if (this.findBreadcrumb(instanceId)) {
                this.updateBreadcrumb(instanceId, data);
            } else {
                this.insertBreadcrumb(instanceId, data);
            }
        },
        findBreadcrumb(instanceId) {
            if (hasProperty(this.breadcrumbs, instanceId)) {
                return this.breadcrumb[instanceId];
            } else return null;
        },
        insertBreadcrumb(instanceId, data) {
            this.breadcrumbs[instanceId] = data;
        },
        updateBreadcrumb(instanceId, data) {
            this.breadcrumbs[instanceId] = data;
        },
        deleteBreadcrumb(instanceId) {
            if (hasProperty(this.breadcrumbs, instanceId)) {
                delete this.breadcrumbs[instanceId];
                return true;
            } else return null;
        },
        initDictionary() {
            const t = i18n.global.t;
            this.entityDictionary = {
                crm: {
                    parrent: null,
                    path: '/crm',
                    name: t('pages.crm'),
                    disabled: true
                },
                LeadCard: {
                    parrent: 'crm',
                    path: '/crm/leads?filter=all_my_active',
                    name: t('pages.leads'),
                    disabled: false
                },
                DealCard: {
                    parrent: 'crm',
                    path: '/crm/opportunities?filter=all_my_active',
                    name: t('pages.deals'),
                    disabled: false
                },
                ContactCard: {
                    parrent: 'crm',
                    path: '/crm/contacts?filter=all_my_active',
                    name: t('pages.contacts'),
                    disabled: false
                },
                CompanyCard: {
                    parrent: 'crm',
                    path: '/crm/accounts?filter=all_my_active',
                    name: t('pages.companies'),
                    disabled: false
                },
                TaskCard: {
                    parrent: null,
                    path: '/crm/tasks?filter=all_active',
                    name: t('pages.tasks'),
                    disabled: false
                },
                MeetingCard: {
                    parrent: 'crm',
                    path: '/crm/activities?filter=all_active',
                    disabled: false
                },
                CallCard: {
                    parrent: 'crm',
                    path: '/crm/activities?filter=all_active',
                    disabled: false
                },
                EmailCard: {
                    parrent: 'crm',
                    path: '/crm/activities?filter=all_active',
                    disabled: false
                },
                CreateShortTask: {
                    parrent: 'crm',
                    path: '/crm/activities?filter=all_active',
                    disabled: false
                }
            };
        }
    }
});
