<template>
    <Teleport to="#footer">
        <Transition name="fade" mode="out-in">
            <div
                class="flex flex-row-reverse items-center bg-white h-[36px] relative z-[10] px-2 border-t shadow-2xl"
                v-if="getComponent.length > 0"
                @contextmenu.stop.prevent="e => showContextMenu(e)">
                <div v-if="isPaginate">
                    <BaseIcon
                        name="rightNavigationArrow"
                        class="h-[34px] mr-2 fill-[#C0C2CD]"
                        :class="{
                            'cursor-pointer': offset < getComponentItems.length - countToWindow,
                            'cursor-not-allowed': offset === getComponentItems.length - countToWindow
                        }"
                        @click="() => (offset < getComponentItems.length - countToWindow ? (offset += 1) : null)" />
                </div>
                <div
                    v-for="component in getComponent"
                    :key="component.id"
                    class="px-2 py-1 mx-[3px] font-['Inter'] font-[400] leading-[24px] text-[14px] h-[32px] flex items-center border relative text-ellipsis whitespace-nowrap cursor-pointer w-[168px] rounded-[12px]"
                    :class="{
                        'bg-blue-50 border-blue-400': component.visible,
                        'bg-white border-[#C0C2CD]': !component.visible
                    }"
                    @mouseover="hoverComponent = component.id"
                    @mouseleave="hoverComponent = null"
                    @click.stop.prevent="
                        () => {
                            $emit('clickComponent', component);
                        }
                    ">
                    <BaseIcon
                        v-if="isLocalIcon(getNameIconToComponent(component))"
                        :name="getNameIconToComponent(component)"
                        viewBox="0 0 24 24"
                        class="h-[24px] w-[24px] mr-1"
                        :class="{
                            'fill-[#C0C2CD]': !component.visible,
                            'fill-blue-500': component.visible
                        }" />
                    <Icon
                        v-else
                        :icon="getNameIconToComponent(component)"
                        class="h-[24px] w-[24px] icon mr-1"
                        :class="{
                            'text-[#C0C2CD]': !component.visible,
                            'text-blue-500': component.visible
                        }"
                        height="24" />
                    <span class="font-normal leading-[24px] text-[14px] truncate w-[110px]">{{ getComponentTitle(component) }}</span>
                    <div class="w-[15px] h-[15px] absolute right-[6px] cursor-pointer text-white" v-if="hoverComponent === component.id">
                        <BaseIcon
                            name="cross"
                            class="fill-[#519DF5]"
                            @click.stop="
                                () => {
                                    addCloseModalWindowLog(component);
                                    $emit('removeComponent', component);
                                    if (offset > 0) {
                                        offset -= 1;
                                    }
                                }
                            " />
                    </div>
                </div>
                <div v-if="isPaginate">
                    <BaseIcon
                        name="leftNavigationArrow"
                        class="h-[34px] mr-2 fill-[#C0C2CD]"
                        :class="{
                            'cursor-pointer': offset > 0,
                            'cursor-not-allowed': offset === 0
                        }"
                        @click="() => (offset > 0 ? (offset -= 1) : null)" />
                </div>
            </div>
        </Transition>
    </Teleport>
    <ContextMenu
        class="z-[1000000]"
        v-if="contextMenuVisible"
        :items="itemsContextMenu"
        @changedValue="handleContextMenu"
        @closeContextMenu="contextMenuVisible = false"
        :event="eventContextMenu"
        :positionOnMouse="true" />
</template>

<script>
import BaseIcon from '@/components/ui/BaseIcon.vue';
import ContextMenu from '@/components/ui/ContextMenu/ContextMenu.vue';
import icons from '@/icons';
import { Icon } from '@iconify/vue';

const viewTypeDefault = 'tray';

export default {
    name: 'BaseTray',
    components: {
        Icon,
        BaseIcon,
        ContextMenu
    },
    emits: ['handleClickContextMenu', 'clickComponent', 'removeComponent'],
    props: {
        componentItems: {
            type: Array,
            default: () => []
        },
        itemsContextMenu: {
            type: Array,
            default: () => []
        },
        parentWidth: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            hoverComponent: null,
            openModal: false,
            contextMenuVisible: false,
            eventContextMenu: null,
            offset: 0,
            isDrag: false,
            startDragX: 0,
            startOffset: 0,
            widthItem: 166,
            translateX: 0,
            viewType: viewTypeDefault, // or "list"
            isMove: false
        };
    },
    computed: {
        getComponentItems() {
            return this.componentItems.filter(item => !item.system);
        },
        countToWindow() {
            if (!this.parentWidth) return this.getCountComponent;
            return Math.floor(this.parentWidth / this.widthItem) - 1;
        },
        isPaginate() {
            return this.getCountComponent > this.countToWindow;
        },
        getComponent() {
            return this.getComponentItems.slice(this.offset, this.offset + this.countToWindow);
        },
        getCountComponent() {
            return this.getComponentItems.length;
        }
    },
    methods: {
        handleContextMenu(data) {
            if (data.id === 'all_close') {
                this.offset = 0;
            }
            this.$emit('handleClickContextMenu', data);
        },
        showContextMenu(e) {
            this.eventContextMenu = e;
            this.contextMenuVisible = true;
            this.openModal = false;

            this.$analytics.addEvent({
                category: 'MultiModalForm',
                action: 'context menu',
                label: 'Open context menu',
                entity: '',
                instance: ''
            });
        },
        getComponentTitle(component) {
            // if (component.trayTitle && component.trayTitle.length > 14) {
            //   return component.trayTitle.substring(0, 14) + "...";
            // }
            return component.trayTitle;
        },
        getNameIconToComponent(component) {
            if (component?.icon) {
                return component.icon;
            }
            return this.getDefaultIcon(component.name);
        },
        getDefaultIcon(name) {
            const icons = {
                CRMAccount: 'heroicons:building-office-2',
                CRMContact: 'fluent-mdl2:workforce-management',
                CRMDeal: 'sidebarOpportunities',
                CRMTask: 'tasks',
                CRMLead: 'sidebarLeads'
            };
            return icons[name] || 'user-02';
        },
        closeTrayQuery() {
            this.timeout = setTimeout(() => {
                this.openModal = false;
            }, 1000);
        },
        openTrayQuery() {
            clearTimeout(this.timeout);
            this.openModal = true;
        },
        drag(event) {
            if (this.isDrag && this.isPaginate) {
                this.isMove = true;
                const diff = event.clientX - this.startDragX;
                const step = diff > 0 ? Math.floor(diff / this.widthItem) : Math.ceil(diff / this.widthItem);
                this.translateX = diff - step * this.widthItem;
                if (this.offset !== this.startOffset - step) {
                    if (this.getCountComponent - this.offset > this.countToWindow && this.offset - step >= 0) {
                        this.offset = this.startOffset - step;
                    }
                    this.translateX = 0;
                }
            }
        },
        isLocalIcon(icon) {
            return Boolean(icons[icon]);
        },
        addCloseModalWindowLog(component) {
            this.$analytics.addEvent({
                category: 'MultiModalForm',
                action: 'lmbc',
                label: 'Close modal window with tray',
                entity: component.name,
                instance: component?.props?.id || component.id,
            });
        }
    },
    watch: {
        isDrag() {
            if (!this.isDrag) {
                this.translateX = 0;
            }
        },
        viewType() {
            // localStorage.setItem("trayViewType", JSON.stringify(this.viewType));
        }
    },
    mounted() {
        // window.addEventListener("mouseup", (ev) => {
        //   ev.preventDefault();
        //   ev.stopPropagation();
        //   this.isDrag = false;
        //   this.translateX = 0;
        // });
        // window.addEventListener("mousemove", this.drag);
    },
    unmounted() {
        // window.removeEventListener("mouseup", (ev) => {
        //   ev.preventDefault();
        //   ev.stopPropagation();
        //   this.isDrag = false;
        //   this.translateX = 0;
        // });
        // window.removeEventListener("mousemove", this.drag);
    }
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

.tasks-submenu__icon {
    width: 20px;
    height: 20px;
    margin-top: 2px;

    transition: all var(--sp-transition-duration, 250ms) var(--sp-transition-function, cubic-bezier(0.4, 0, 0.2, 1));
}
</style>
