<template>
    <p class="breadcrumb-item flex items-center overflow-hidden gap-1">
        <span class="breadcrumb-point shrink-0" :style="{ 'background-color': last ? activeColor : colorFont || '#979BB2' }"></span>
        
        <BaseIcon
            v-if="item.icon?.name"
            :name="item.icon?.name"
            class="h-[24px] w-[24px]"
            :style="{
                fill: item.icon?.fill
            }"
        ></BaseIcon>
        
        <span
            v-if="item.disabled || last"
            class="breadcrumb-item truncate font-[400]"
            :class="{ 'last-item': last }"
            :style="{ color: colorFont || last ? activeColor : '#979BB2' }"
            v-tooltip.bottom="item.name"
            >{{ item.name }}</span
        >

        <!-- :style="{ 'max-width': `${maxWidth}px` }" -->
        <router-link 
            v-else
            :to="item.path"
            class="mx-1 breadcrumb-item active-item truncate"
            v-tooltip.bottom="item.name"
            @click="logClick(item)"
        >
            <span :style="{ color: colorFont || '#c0c2cd' }">{{ item.name }}</span>
        </router-link>
    </p>
</template>

<script>
import BaseIcon from '@/components/ui/BaseIcon.vue';

export default {
    name: 'BreadcrumbItem',
    components: {
        BaseIcon
    },
    props: {
        item: {
            type: Object
        },
        last: {
            type: Boolean
        },
        // maxWidth: {
        //   type: Number,
        //   default: 150,
        // },
        activeColor: {
            type: String,
            default: '#979BB2'
        },
        colorFont: {
            type: String
        }
    },
    emits: [],
    data() {
        return {};
    },
    computed: {},
    methods: {
        logClick() {
            this.$analytics.addEvent({
                category: 'Top navigation panel',
                action: 'lmbc',
                label: 'Click to navigate through the project / сhange project or folder',
                entity: 'task',
                instance: this.item.name
            });
        }
    }
};
</script>
<style scoped>
.breadcrumb-item {
    &:first-child {
        .breadcrumb-point {
            display: none;
        }
    }
}
.breadcrumb-item {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    /* max-width: 150px; */
}
.active-item:hover {
    color: rgb(99, 98, 98);
}

.breadcrumb-point {
    width: 6px;
    height: 6px;
    margin: 9px;
    border-radius: 3px;
}
</style>
